import { createStore } from 'vuex';
import { RootState } from './stateModel';
import getters from './getters';
import user from './user';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const state: RootState = {
    menuHidden: false
};

export default createStore({
    state,
    getters,
    modules: {
        user
    },
    mutations: {
        _toogleSideShrink(state: RootState, status?: boolean) {
            if (typeof status !== 'undefined') {
                state.menuHidden = status;
            } else {
                state.menuHidden = !state.menuHidden;
            }
        }
    },
    actions: {
        toogleSideShrink({ commit }, status?: boolean) {
            commit('_toogleSideShrink', status);
        }
    }
});
