import { HTTP } from './http';

class Base {
    public errorHandle(error: HttpException): Promise<ApiResult> {
        return Promise.resolve({ error });
    }

    public successHandle(data: unknown): Promise<ApiResult> {
        return Promise.resolve({ data });
    }
}

class Accounts extends Base {
    constructor() {
        super();
    }

    public async getLogin(phone: string) {
        return HTTP.send('/api/cemetapub/usermanager/v1/account/login', 'get', { params: { account: phone } }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async sendLoginPhoneCode(phone: string, companyId: string) {
        return HTTP.send(`/api/cemetapub/message/v1/phone/${phone}/code`, 'get', { params: { type: 'admin-login-phone-code' }, headers: { 'x-company': companyId } }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async login(type: 'code' | 'resume', payload: { phone?: string, code?: string, token?: string }, companyId: string) {
        return HTTP.send('/api/cemetapub/usermanager/v1/account/login', 'post', { data: { type, payload }, headers: { 'x-company': companyId } }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }
}

class Users extends Base {
    constructor() {
        super();
    }

    public async getUserList(option?: { skip?: number, limit?: number, phone?: string }) {
        return HTTP.send('/api/cemetaadm/usermanager/v1/account/user', 'get', { params: option }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async removeUserFromCompany(userId: string) {
        return HTTP.send(`/api/cemetaadm/usermanager/v1/account/user/${userId}`, 'delete').then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async addUserToCompany(user?: { phone: string, name?: string }) {
        return HTTP.send('/api/cemetaadm/usermanager/v1/account/user', 'post', { data: user }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }
}

class Resources extends Base {
    constructor() {
        super();
    }

    public async getList(option?: { skip?: number, limit?: number }) {
        return HTTP.send('/api/cemetaadm/aimanager/v1/resource', 'get', { params: option }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async addResource(data: { type: 'cjt_sku', file: string, mark: string, name: string, appId: string }) {
        return HTTP.send('/api/cemetaadm/aimanager/v1/resource', 'post', { data: data }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }

    public async updateResource(resourceId: string, update: { status?: 'active' | 'disabled' }) {
        return HTTP.send(`/api/cemetaadm/aimanager/v1/resource/${resourceId}`, 'put', { data: update }).then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }
}

class Applications extends Base {
    constructor() {
        super();
    }

    public async getList() {
        return HTTP.send('/api/cemetaadm/application/v1/app', 'get').then(r => this.successHandle(r)).catch(e => this.errorHandle(e));
    }
}

export default {
    Account: new Accounts(),
    User: new Users(),
    Resource: new Resources(),
    Application: new Applications()
};
