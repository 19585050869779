import { createRouter, createWebHashHistory, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [{
    path: '/',
    redirect: '/login'
}, {
    path: '/login',
    meta: { title: '登录' },
    component: () => import(/* webpackChunkName: 'login' */ '../views/login.vue')
}, {
    path: '/admin',
    redirect: '/index',
    component: () => import(/* webpackChunkName: 'layout' */ '../views/layout/index.vue'),
    children: [
        /** 首页 */
        {
            path: '/index',
            meta: { title: '首页' },
            component: () => import(/* webpackChunkName: 'index' */ '../views/home.vue')
        },
        /** 用户管理 */
        {
            path: '/user',
            meta: { title: '用户管理' },
            component: () => import(/* webpackChunkName: 'user-list' */ '../views/user/list.vue')
        },
        {
            path: '/resource',
            meta: { title: '资源管理' },
            component: () => import(/* webpackChunkName: 'resource-list' */ '../views/resource/list.vue')
        }
    ]
}];


const router = createRouter({
    history: process.env.NODE_ENV === 'development' ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
    routes
});


/**
 * 全局导航守卫
 */

/* 前置导航守卫 */
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    // do something before next route
    next();
});

/* 后置导航守卫 */
router.afterEach((to: RouteLocationNormalized/*, from: RouteLocationNormalized, failure?: NavigationFailure | void*/) => {
    document.title = `CeMeta ${to.meta.title}`;
});

export default router;
