import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import { ElementPlusIconsVue } from './ui-frame';

import './assets';

const app = createApp(App)
    .use(store)
    .use(router);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
app.mount('#app');
