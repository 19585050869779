import { MutationTree, ActionTree, Module } from 'vuex';
import { RootState, UserState } from './stateModel';
import Api from '@/api';
import router from '../router';
import { Tips } from '@/ui-frame';

const state: UserState = {};
const mutations: MutationTree<UserState> = {
    _login(state: UserState, user: UserState) {
        state.token = user.token;
        state.user = user.user;
        state.company = user.company;

        if (user.token && user.user?.companyId) {
            window.localStorage.setItem('cemeta_admin_token', user.token);
            window.localStorage.setItem('cemeta_admin_company', user.user?.companyId);
            router.replace({
                path: '/index'
            });
        }
    },
    _logout(state: UserState) {
        window.localStorage.removeItem('cemeta_admin_token');
        window.localStorage.removeItem('cemeta_admin_company');
        state.company = undefined;
        state.token = undefined;
        state.user = undefined;

        router.replace({
            path: '/login'
        });
    }
};

const actions: ActionTree<UserState, RootState> = {
    async login({ commit }, option: { type: 'code' | 'resume', phone?: string, code?: string, companyId?: string }) {
        const { type, phone, code, companyId } = option;
        // const viewPath = window.location.href.replace(window.location.origin, '').replace('/#', '');

        // if (viewPath !== '/ops/index' && viewPath !== '/login') {
        //     commit('_rememberTargetPath', viewPath);
        // }
        let user: ApiResult = {};

        if (type === 'code') {
            if (!phone || !code || !companyId) {
                return;
            }
            user = await Api.Account.login('code', { phone, code }, companyId);
        }

        if (type === 'resume') {
            const token = window.localStorage.getItem('cemeta_admin_token');
            const companyId = window.localStorage.getItem('cemeta_admin_company');

            if (!token || !companyId) {
                router.replace({
                    path: '/login'
                });
                return;
            }
            user = await Api.Account.login('resume', { token }, companyId);
        }

        if (user.data) {
            return commit('_login', user.data);
        } else {
            if (type !== 'resume') {
                Tips.error('登录失败！');
            }
            router.replace({
                path: '/login'
            });
            return;
        }
    },
    logout({ commit }) {
        return commit('_logout');
    }
};

const user: Module<UserState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions
};

export default user;
